<template>
  <div id="app">
    <headerdiv />
    <transition mode="out-in" name="fadeInZoom">
      <div v-if="this.loaderHide" class="router">
        <transition mode="out-in" name="fadeIn">
          <router-view :key="$route.fullPath" />
        </transition>
      </div>
    </transition>
    <transition name="fadeIn">
      <loader v-if="!this.loaderHide" />
    </transition>
  </div>
</template>
<script>
import loader from "@/components/loading.vue";
import headerdiv from "@/partials/header.vue";

export default {
  data() {
    return {
      loaderHide: false,
    };
  },

  components: {
    loader,
    headerdiv
  },

  computed: {
    postsLoaded() {
      return `${this.$store.state.pagesLoaded}|${this.$store.state.portfolioLoaded}|${this.$store.state.imagesLoaded}|${this.$store.state.categoriesLoaded}`;
    },

    lengthsComputed() {
      return `${this.$store.state.maxPages}|${this.$store.state.maxPortfolio}|${this.$store.state.maxCategories}|${this.$store.state.maxImages}`;
    },
  },

  async created() {
    const pages = this.$store.dispatch("getPagesLength");
    const portfolio = this.$store.dispatch("getPortfolioLength");
    const categories = this.$store.dispatch("getCategoriesLength");
    const images = this.$store.dispatch("getImagesLength");
    return await Promise.all([pages, portfolio, categories, images]).then(
      () => {
        console.log("Loaded data for all posts.");
      }
    );
  },

  methods: {
    toggleLoader: function (isReady) {
      if (isReady === true) {
        setTimeout(this.hideLoader, 1000);
      }
    },
    hideLoader() {
      this.loaderHide = true;
    },
  },

  watch: {
    postsLoaded() {
      if (
        (this.$store.state.pagesLoaded === true) &
        (this.$store.state.portfolioLoaded === true) &
        (this.$store.state.imagesLoaded === true) &
        (this.$store.state.categoriesLoaded === true)
      ) {
        this.toggleLoader(true);
      }
    },
    lengthsComputed() {
      if (this.$store.state.maxPages != 99999) {
        this.$store.dispatch("getPages");
      }
      if (this.$store.state.maxPortfolio != 99999) {
        this.$store.dispatch("getPortfolio");
      }
      if (this.$store.state.maxImages != 99999) {
        this.$store.dispatch("getImages");
      }
      if (this.$store.state.maxCategories != 99999) {
        this.$store.dispatch("getCategories");
      }
      if (this.$store.state.maxCategories != 99999) {
        this.$store.dispatch("getCategories");
      }
    },
  },
};
</script>

<style>
</style>
