import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'


// Define Wordpress Getter Constants

// Axios URLs
const mainUrl = 'https://russell-brown.co.uk';
const nativeWP = '/wp-json/wp/v2';
const getterPages = '/pages';
const getterImages = '/media';
const getterCategories = '/categories';
const getterPortfolio = '/portfolio';

//Queries
const perPage = 50;
const perRequest = '?per_page=' + perPage;
const pageNumRequest = '&page=';

// Dummy Value To Be Changed! (9999)
// const getterPosts = '/posts';


// Vuex Start
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pages: [],
    siteLoaded: false,
    portfolio: [],
    categories: [],
    images: [],
    pagesLoaded: false,
    portfolioLoaded: false,
    imagesLoaded: false,
    categoriesLoaded: false,
    maxPages: 99999,
    maxPortfolio: 99999,
    maxImages: 99999,
    maxCategories: 99999,
    pageNumberID: 1,
    portfolioPageNumberID: 1,
    imagesPageNumberID: 1,
    categoriesPageNumberID: 1,
    imagePreLoad: false,
    navBarHide: false,
    navLarge: true,
  },

  mutations: {
    //Utilities
    SET_LOAD_STATE(state) {
      state.siteLoaded = true;
    },
    SET_PRELOAD_STATE(state) {
      state.imagePreLoad = true;
    },
    TOGGLE_NAVBAR_HIDE(state,value) {
      state.navBarHide = value;
      console.log(state.navBarHide)
    },
    TOGGLE_NAVBAR_SIZE(state, value) {
      state.navLarge = value;
      console.log(state.navLarge);
    },


    // Pages
    SET_PAGES(state, pages) {
      state.pages = pages;
      console.log('Set Pages');
    },
    SET_PAGES_PUSH(state, pages) {
      state.pages.push(pages);
      console.log('Push Pages');
    },
    SET_PAGE_LOAD_STATE(state) {
      state.pagesLoaded = true;
    },
    SET_MAX_PAGES(state, max) {
      state.maxPages = parseInt(max);
      console.log('Return max pages: ' + state.maxPages);
    },
    SET_PAGES_PAGE_NUMBER(state, number) {
      state.pageNumberID = number;
    },
    INCREMENT_PAGE_PAGES(state) {
      state.pageNumberID = state.pageNumberID + 1;
    },

    // Portfolio
    SET_PORTFOLIO(state, portfolio) {
      state.portfolio = portfolio;
      console.log('Set Portfolio');
    },
    SET_PORTFOLIO_PUSH(state, portfolio) {
      state.portfolio.push(portfolio);
      console.log('Push Portfolio');
    },
    SET_PORTFOLIO_LOAD_STATE(state) {
      state.portfolioLoaded = true;
    },
    SET_MAX_PORTFOLIO(state, max) {
      state.maxPortfolio = parseInt(max);
      console.log('Return max portfolio pages: ' + state.maxPortfolio);
    },
    SET_PAGES_PORTFOLIO_NUMBER(state, number) {
      state.portfolioPageNumberID = number;
    },
    INCREMENT_PORTFOLIO_PAGES(state) {
      state.portfolioPageNumberID = state.portfolioPageNumberID + 1;
    },

    // Images
    SET_IMAGES(state, images) {
      state.images = images;
      console.log('Set Images');
    },
    SET_IMAGES_PUSH(state, images) {
      state.images.push(images);
      console.log('Push Images');
    },
    SET_IMAGES_LOAD_STATE(state) {
      state.imagesLoaded = true;
    },
    SET_MAX_IMAGES(state, max) {
      state.maxImages = parseInt(max);
      console.log('Return max image pages: ' + state.maxImages);
    },
    SET_IMAGES_PORTFOLIO_NUMBER(state, number) {
      state.imagesPageNumberID = number;
    },
    INCREMENT_IMAGES_PAGES(state) {
      state.imagesPageNumberID = state.imagesPageNumberID + 1;
    },

    // Categories
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
      console.log('Set Categories');
    },
    SET_CATEGORIES_PUSH(state, categories) {
      state.portfolio.push(categories);
      console.log('Push Categories');
    },
    SET_CATEGORIES_LOAD_STATE(state) {
      state.categoriesLoaded = true;
    },
    SET_MAX_CATEGORIES(state, max) {
      state.maxCategories = parseInt(max);
      console.log('Return max categorypages: ' + state.maxCategories);
    },
    SET_CATEGORIES_PORTFOLIO_NUMBER(state, number) {
      state.categoriesPageNumberID = number;
    },
    INCREMENT_CATEGORIES_PAGES(state) {
      state.categoriesPageNumberID = state.categoriesPageNumberID + 1;
    },

  },

  actions: {
    async getPagesLength({
      commit,
      state
    }) {
      // console.log('not ' + mainUrl)
      let maxPage;

      await axios({
          method: 'get',
          url: mainUrl + nativeWP + getterPages + perRequest + pageNumRequest + state.pageNumberID,
        })

        .then(response => {

          // console.log(response.data);
          // console.log('Does this fucking work? ' + parseInt(response.headers['x-wp-totalpages']));
          console.log('Commit Max Pages');
          // console.log(response.headers['x-wp-totalpages']);

          maxPage = response.headers['x-wp-totalpages'];
          commit('SET_MAX_PAGES', maxPage);

          // //TEMPORARY
          // commit('SET_COURSE_LOAD_STATE_TOGGLE');

        })

        .catch(error => {
          // handle error
          console.error(error);
        })

      // if (maxPage) {

      // }
    },

    async getPages({
      commit,
      state
    }) {

      await axios({
          method: 'get',
          url: mainUrl + nativeWP + getterPages + perRequest + pageNumRequest + state.pageNumberID,
        })

        .then(response => {

          console.log('Got Response ' + state.pageNumberID);

          if (state.pageNumberID === 1) {
            commit('SET_PAGES', response.data);
            // console.log(response.data); 

          } else {

            var arrayID = response.data.length - 1;
            console.log('Pushing Pages Array');

            for (let i = 0; i <= arrayID; i++) {
              // console.log(response.data[arrayID].id);
              commit('SET_PAGES_PUSH', response.data[i]);
            }
          }


          if (state.pageNumberID === state.maxPages) {

            console.log('Page pull complete');
            commit('SET_PAGE_LOAD_STATE');

          } else {
            // classPageNumberID = classPageNumberID + 1;

            commit('INCREMENT_PAGE_PAGES');
            console.log('Getting next response.');
            this.dispatch("getPages");
          }

          // console.log(mainUrl + getterPages + perRequest + pageNumber + i)
        })
        .catch(error => {
          // handle error
          console.error(error);
        })

    },

    async getPortfolioLength({
      commit,
      state
    }) {
      // console.log('not ' + mainUrl)
      let maxPage;

      await axios({
          method: 'get',
          url: mainUrl + nativeWP + getterPortfolio + perRequest + pageNumRequest + state.portfolioPageNumberID,
        })

        .then(response => {

          // console.log(response.data);
          // console.log('Does this fucking work? ' + parseInt(response.headers['x-wp-totalpages']));
          console.log('Commit Max Portfolio Pages');
          // console.log(response.headers['x-wp-totalpages']);

          maxPage = response.headers['x-wp-totalpages'];
          commit('SET_MAX_PORTFOLIO', maxPage);

          // //TEMPORARY
          // commit('SET_COURSE_LOAD_STATE_TOGGLE');

        })

        .catch(error => {
          // handle error
          console.error(error);
        })

      // if (maxPage) {

      // }
    },

    async getPortfolio({
      commit,
      state
    }) {

      await axios({
          method: 'get',
          url: mainUrl + nativeWP + getterPortfolio + perRequest + pageNumRequest + state.portfolioPageNumberID,
        })

        .then(response => {

          console.log('Got Response ' + state.portfolioPageNumberID);

          if (state.portfolioPageNumberID === 1) {
            commit('SET_PORTFOLIO', response.data);
            // console.log(response.data); 

          } else {

            var arrayID = response.data.length - 1;
            console.log('Pushing Portfolio Array');

            for (let i = 0; i <= arrayID; i++) {
              // console.log(response.data[arrayID].id);
              commit('SET_PORTFOLIO_PUSH', response.data[i]);
            }
          }


          if (state.portfolioPageNumberID === state.maxPortfolio) {

            console.log('Portfolio pull complete');
            commit('SET_PORTFOLIO_LOAD_STATE');

          } else {
            // classPageNumberID = classPageNumberID + 1;

            commit('INCREMENT_PORTFOLIO_PAGES');

            this.dispatch("getPortfolio");
          }

          // console.log(mainUrl + getterPages + perRequest + pageNumber + i)
        })
        .catch(error => {
          // handle error
          console.error(error);
        })

    },





    async getImagesLength({
      commit,
      state
    }) {
      // console.log('not ' + mainUrl)
      let maxPage;

      await axios({
          method: 'get',
          url: mainUrl + nativeWP + getterImages + perRequest + pageNumRequest + state.imagesPageNumberID,
        })

        .then(response => {

          // console.log(response.data);
          // console.log('Does this fucking work? ' + parseInt(response.headers['x-wp-totalpages']));
          console.log('Commit Max Image Pages');
          // console.log(response.headers['x-wp-totalpages']);

          maxPage = response.headers['x-wp-totalpages'];
          commit('SET_MAX_IMAGES', maxPage);

          // //TEMPORARY
          // commit('SET_COURSE_LOAD_STATE_TOGGLE');

        })

        .catch(error => {
          // handle error
          console.error(error);
        })

      // if (maxPage) {

      // }
    },

    async getImages({
      commit,
      state
    }) {

      await axios({
          method: 'get',
          url: mainUrl + nativeWP + getterImages + perRequest + pageNumRequest + state.imagesPageNumberID,
        })

        .then(response => {

          console.log('Got Response ' + state.imagesPageNumberID);

          if (state.imagesPageNumberID === 1) {
            commit('SET_IMAGES', response.data);
            // console.log(response.data); 

          } else {

            var arrayID = response.data.length - 1;
            console.log('Pushing Images Array');

            for (let i = 0; i <= arrayID; i++) {
              // console.log(response.data[arrayID].id);
              commit('SET_IMAGES_PUSH', response.data[i]);
            }
          }


          if (state.imagesPageNumberID === state.maxImages) {

            console.log('Image pull complete');
            commit('SET_IMAGES_LOAD_STATE');

          } else {
            // classPageNumberID = classPageNumberID + 1;

            commit('INCREMENT_IMAGES_PAGES');

            this.dispatch("getImages");
          }

          // console.log(mainUrl + getterPages + perRequest + pageNumber + i)
        })
        .catch(error => {
          // handle error
          console.error(error);
        })

    },





    async getCategoriesLength({
      commit,
      state
    }) {
      // console.log('not ' + mainUrl)
      let maxPage;

      await axios({
          method: 'get',
          url: mainUrl + nativeWP + getterCategories + perRequest + pageNumRequest + state.categoriesPageNumberID,
        })

        .then(response => {

          // console.log(response.data);
          // console.log('Does this fucking work? ' + parseInt(response.headers['x-wp-totalpages']));
          console.log('Commit Max Category Pages');
          // console.log(response.headers['x-wp-totalpages']);

          maxPage = response.headers['x-wp-totalpages'];
          commit('SET_MAX_CATEGORIES', maxPage);

          // //TEMPORARY
          // commit('SET_COURSE_LOAD_STATE_TOGGLE');

        })

        .catch(error => {
          // handle error
          console.error(error);
        })

      // if (maxPage) {

      // }
    },

    async getCategories({
      commit,
      state
    }) {

      await axios({
          method: 'get',
          url: mainUrl + nativeWP + getterCategories + perRequest + pageNumRequest + state.categoriesPageNumberID,
        })

        .then(response => {

          console.log('Got Response ' + state.categoriesPageNumberID);

          if (state.categoriesPageNumberID === 1) {
            commit('SET_CATEGORIES', response.data);
            // console.log(response.data); 

          } else {

            var arrayID = response.data.length - 1;
            console.log('Pushing Categories Array');

            for (let i = 0; i <= arrayID; i++) {
              // console.log(response.data[arrayID].id);
              commit('SET_CATEGORIES_PUSH', response.data[i]);
            }
          }


          if (state.categoriesPageNumberID === state.maxCategories) {

            console.log('Category pull complete');
            commit('SET_CATEGORIES_LOAD_STATE');

          } else {
            // classPageNumberID = classPageNumberID + 1;

            commit('INCREMENT_CATEGORIES_PAGES');

            this.dispatch("getCategories");
          }

          // console.log(mainUrl + getterPages + perRequest + pageNumber + i)
        })
        .catch(error => {
          // handle error
          console.error(error);
        })

    },
  },

  modules: {

  },

  getters: {
    getPage: (state) => (slug) => {
      var slugProcessed = 'https://russell-brown.co.uk' + slug;
      return state.pages.find(page => page.link === slugProcessed);
    },
    
    getPortfolio: (state) => (limit) => {
      let portfolioFeed = state.portfolio;


      for (let i = portfolioFeed.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [portfolioFeed[i], portfolioFeed[j]] = [portfolioFeed[j], portfolioFeed[i]];
      }
      

      return portfolioFeed.slice(0, limit);

    },

    getPortfolioByCategory: (state) => (limit, id) => {
      let portfolioFeed = state.portfolio.filter(post => post.categories.includes(id));
      
      for (let i = portfolioFeed.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [portfolioFeed[i], portfolioFeed[j]] = [portfolioFeed[j], portfolioFeed[i]];
      }



      return portfolioFeed.slice(0, limit);

    },
    getPortfolioAll: (state) => {

      let portfolioFeed = state.portfolio;


      for (let i = portfolioFeed.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [portfolioFeed[i], portfolioFeed[j]] = [portfolioFeed[j], portfolioFeed[i]];
      }

      return portfolioFeed;

    },

    getPortfolioSingle: (state) => (id) => {

      let portfolio = state.portfolio.find(post => post.id === id);

      if (Array.isArray(portfolio.acf.photos)) {
        console.log("Already an Array!");
      } else {
        if (portfolio !== 0 || portfolio !== -1 || portfolio !== null || portfolio !== undefined) {
          console.log(portfolio.acf.photos);
          let array = "[" + portfolio.acf.photos + "]";
          console.log(array);
          let toConvert = JSON.parse(array);
          portfolio.acf.photos = toConvert;
        }
      }

      let imageLength = portfolio.acf.photos.length - 1;

      for (let i = 0; i <= imageLength; i++) {

        let a = state.images.find(image => image.id === portfolio.acf.photos[i]);

        if (a === undefined || a === null || a === -1 || a === 0) {
          console.log(portfolio.acf.photos[i]);

        } else {

          let b = a.media_details.sizes.full.source_url;
          portfolio.acf.photos[i] = b;
        }

      }

      return portfolio;
    },

    getCategoryName: (state) => (id) => {
      let category = state.categories.find(category => category.id === id); 

      return category.name;

    }
  }
})