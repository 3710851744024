<template>
    <div class="initial-loading">
        <div class="loading-container">
            <div class="loading-bar" :style="'width: ' + this.progress + '%;'"></div>
        </div>
        <transition name="fadeIn" appear>
            <p class="loading-text">RUSSELL BROWN INTERIORS</p>
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
           progress: 0, 
           portfolioLoaded: false,
           imagesLoaded: false,
           pagesLoaded: false,
           categoriesLoaded: false,
        }
    },
    methods: {
        loadingProgress() {
            if (this.pagesLoaded != true) {
                if (this.$store.state.pagesLoaded === true) {
                    this.progress = this.progress + 25;
                    this.pagesLoaded = true;
                }
            }
            if (this.imagesLoaded != true) {
                if (this.$store.state.portfolioLoaded === true) {
                    this.progress = this.progress + 25;
                    this.portfolioLoaded = true;
                }
            }

            if (this.imagesLoaded != true) {
                if (this.$store.state.imagesLoaded === true) {
                    this.progress = this.progress + 25;
                    this.imagesLoaded = true;
                }
            }
            if (this.categoriesLoaded != true) {
                if (this.$store.state.categoriesLoaded === true) {
                    this.progress = this.progress + 25;
                    this.categoriesLoaded = true;
                }
            }

            if ((this.$store.state.categoriesLoaded === true) & (this.$store.state.portfolioLoaded === true) & (this.$store.state.imagesLoaded === true) & (this.$store.state.categoriesLoaded === true)){
                this.progress = 100;
            }

        }
    },
    computed: {
        postsLoaded() {
        return `${this.$store.state.pagesLoaded}|${this.$store.state.portfolioLoaded}|${this.$store.state.imagesLoaded}|${this.$store.state.categoriesLoaded}`;
        },
    },
    watch: {
        postsLoaded() {
            this.loadingProgress();
        },
    }
}
</script>