<template>
  <div class="page home">
    <div class="slider-indic">
      <div
        @click="changeSlide(slide.id)"
        :class="currentSlide === slide.id ? 'indic-active' : 'indic'"
        v-for="(slide, index) in homeSlider"
        :key="index"
      ></div>
    </div>
    <div class="header">
      <div class="header-body">
        <div class="text">
          <h1 class="hero-header">
            Pure quality <br />
            is timeless.
          </h1> 
          <p class="hero-body">
            Russell Brown Interiors combine modern appeal with classic
            sophistication, creating statement interiors where luxury, style and
            serenity live in harmony.
          </p>
          <p class="hero-body">
            Over 40 years of specialising in bespoke design for homes of
            distinction, we have established our reputation for simple elegance
            and the highest standards of perfection.
          </p>
          <a href="#" v-scroll-to="'#start'">
            <img
              src="/flex-content/uploads/2020/11/downarrow.svg"
              class="down"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="page-body" id="start">
      <intersect :threshold="[0.25, 0.25]" @enter="bigNav(false)" @leave="bigNav(true)" >      
      <div class="page-body-inner">
        <section style="text-align: center">
          <h2>Our Work</h2>
          <div class="portfolio-list">
            <div
              v-for="(portfolio, index) in portfolioData"
              :key="index"
              :class="index === 0 ? 'first-post' : 'post'"
            >
              <portfoliocard :postId="portfolio.id" />
            </div>
          </div>
          <div class="btn-container" id="center">
            <router-link class="btn" id="clear-blue" to="/our-work"
              >View more work
              <div class="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22.8"
                  height="18.051"
                  viewBox="0 0 22.8 18.051"
                >
                  <g
                    id="left-arrow"
                    transform="translate(22.8 71.377) rotate(180)"
                  >
                    <path
                      id="Path_1"
                      data-name="Path 1"
                      d="M22.336,61.871H1.633l7.74-7.739A.475.475,0,0,0,8.7,53.46L.151,62.01a.475.475,0,0,0,0,.672h0l8.55,8.55a.475.475,0,0,0,.683-.66l-.012-.012-7.74-7.74h20.7a.475.475,0,0,0,0-.95Z"
                      transform="translate(-0.011 0)"
                    />
                    <path
                      id="Path_2"
                      data-name="Path 2"
                      d="M9.025,71.37a.475.475,0,0,1-.336-.139l-8.55-8.55a.475.475,0,0,1,0-.672l8.55-8.55a.475.475,0,0,1,.672.672L1.147,62.345l8.214,8.214a.475.475,0,0,1-.335.811Z"
                      transform="translate(0)"
                    />
                    <path
                      id="Path_3"
                      data-name="Path 3"
                      d="M22.333,246.135H.483a.475.475,0,1,1,0-.95h21.85a.475.475,0,0,1,0,.95Z"
                      transform="translate(-0.008 -183.315)"
                    />
                  </g>
                </svg>
              </div>
            </router-link>
          </div>
        </section>
        <section>
          <div class="col-cent cta">
            <div class="gradient-background">
              <imgdivloader
                :classes="'img-bg AppImage'"
                :ids="'center'"
                :lazySrc="'/flex-content/uploads/2020/11/IMG_0023-RussellBrown-denoise-scaled.jpg'"
              />
              <div class="overlay"></div>
            </div>
            <div class="col-container russell-cta">
              <img
                class="fleur"
                src="/flex-content/uploads/2020/11/fleur_Top.svg"
              /><br />
              <h2>
                With a Russell Brown Interior your expectations are surpassed
                every time
              </h2>
              <p>
                Russell Brown is a designer with a simple ethos – “Say what you
                do and do what you say.” His hallmark style and demand for
                exceptional standards are reflected in the refined flair which
                characterises every design. Every luxurious material, exclusive
                fabric and hand-gilded line has been individually chosen and
                placed exclusively by him.
              </p>
              <p>
                Russell Brown Interiors deliver quality without exception. Expect nothing less.
              </p>
              <div class="btn-container" id="center">
                <router-link class="btn" id="cream" to="/our-story"
                  >Our Story
                  <div class="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.8"
                      height="18.051"
                      viewBox="0 0 22.8 18.051"
                    >
                      <g
                        id="left-arrow"
                        transform="translate(22.8 71.377) rotate(180)"
                      >
                        <path
                          id="Path_1"
                          data-name="Path 1"
                          d="M22.336,61.871H1.633l7.74-7.739A.475.475,0,0,0,8.7,53.46L.151,62.01a.475.475,0,0,0,0,.672h0l8.55,8.55a.475.475,0,0,0,.683-.66l-.012-.012-7.74-7.74h20.7a.475.475,0,0,0,0-.95Z"
                          transform="translate(-0.011 0)"
                        />
                        <path
                          id="Path_2"
                          data-name="Path 2"
                          d="M9.025,71.37a.475.475,0,0,1-.336-.139l-8.55-8.55a.475.475,0,0,1,0-.672l8.55-8.55a.475.475,0,0,1,.672.672L1.147,62.345l8.214,8.214a.475.475,0,0,1-.335.811Z"
                          transform="translate(0)"
                        />
                        <path
                          id="Path_3"
                          data-name="Path 3"
                          d="M22.333,246.135H.483a.475.475,0,1,1,0-.95h21.85a.475.475,0,0,1,0,.95Z"
                          transform="translate(-0.008 -183.315)"
                        />
                      </g>
                    </svg>
                  </div>
                </router-link>
                <router-link class="btn" id="blue" to="/contact"
                  >Contact
                  <div class="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.133"
                      height="25.131"
                      viewBox="0 0 25.133 25.131"
                    >
                      <g id="call" transform="translate(511 909.982)">
                        <g
                          id="Group_7"
                          data-name="Group 7"
                          transform="translate(-511 -909.982)"
                        >
                          <g
                            id="Group_6"
                            data-name="Group 6"
                            transform="translate(0 0)"
                          >
                            <path
                              id="Path_5"
                              data-name="Path 5"
                              d="M25.474,20.406l-5.087-3.392a1.485,1.485,0,0,0-1.988.323l-1.482,1.905a.633.633,0,0,1-.812.171l-.282-.155a17.034,17.034,0,0,1-4.439-3.487A17.1,17.1,0,0,1,7.9,11.331l-.155-.282a.634.634,0,0,1,.168-.815l1.9-1.481a1.486,1.486,0,0,0,.323-1.988L6.744,1.677a1.477,1.477,0,0,0-1.991-.448L2.626,2.507A3,3,0,0,0,1.258,4.283c-.766,2.791-.19,7.608,6.906,14.7,5.644,5.644,9.847,7.162,12.735,7.162a7.42,7.42,0,0,0,1.968-.257,3,3,0,0,0,1.776-1.368L25.922,22.4A1.477,1.477,0,0,0,25.474,20.406ZM25.2,21.968,23.924,24.1a2.165,2.165,0,0,1-1.277.99c-2.577.707-7.078.121-13.89-6.691S1.358,7.081,2.066,4.5a2.168,2.168,0,0,1,.991-1.279L5.184,1.949a.641.641,0,0,1,.864.194L7.891,4.91,9.438,7.23a.645.645,0,0,1-.14.863l-1.9,1.481a1.466,1.466,0,0,0-.388,1.881l.151.275a17.767,17.767,0,0,0,3.63,4.632,17.786,17.786,0,0,0,4.632,3.63l.275.152a1.466,1.466,0,0,0,1.881-.388l1.481-1.9a.645.645,0,0,1,.863-.14L25.006,21.1A.64.64,0,0,1,25.2,21.968Z"
                              transform="translate(-1 -1.018)"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </section>
      </div>
      </intersect>
    </div>
    <div class="home-slider">
      <div class="overlay"></div>
      <div class="slideshow">
        <div class="container">
          <div class="slide" v-for="(slide, index) in homeSlider" :key="index">
            <transition name="fadeInSlow">
              <div v-show="currentSlide === slide.id">
                <div
                  class="bg"
                  :style="'background-image: url(' + slide.url + ');'"
                ></div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <footerdiv />
  </div>
</template>


<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import portfoliocard from "@/partials/portfolioCard.vue";
import imgdivloader from "@/partials/ImgLoaderDiv.vue";
import footerdiv from "@/partials/footer.vue";
import intersect from 'vue-intersect';

export default {
  name: "Home",

  data() {
    return {
      homeSlider: [],
      sliderLength: 0,
      currentSlide: 1,
      timeout: null,
      portfolioData: null,
    };
  },


  components: {
    portfoliocard,
    imgdivloader,
    footerdiv,
    intersect
  },

  computed: {
    pageData() {
      var routePath = this.$route.fullPath;

      if (routePath === "/") {
        var permaLink = "/";
      } else {
        permaLink = this.$route.path + "/";
      }

      return this.$store.getters.getPage(permaLink);
    },
  },

  mounted() {
    this.prepSlider();
    this.getCards();
    this.bigNav(true);
  },

  methods: {
    getCards() {
      this.portfolioData = this.$store.getters.getPortfolio(7);
    },
    bigNav(value) {
        this.$store.commit('TOGGLE_NAVBAR_SIZE', value);
    },
    changeSlide(value) {
      clearTimeout(this.timeout);
      this.currentSlide = value;
      this.timeout = setTimeout(this.startSlider, 10000);
    },
    prepSlider() {
      if (
        this.pageData.acf.hero_image_1 === null ||
        this.pageData.acf.hero_image_1 === undefined
      ) {
        return console.log("Error Loading Slide");
      } else {
        if (this.pageData.acf.hero_image_1 != false) {
          let slide1 = this.pageData.acf.hero_image_1;
          this.homeSlider.push({ url: slide1, id: 1 });
        }

        if (this.pageData.acf.hero_image_2 != false) {
          let slide2 = this.pageData.acf.hero_image_2;
          this.homeSlider.push({ url: slide2, id: 2 });
        }

        if (this.pageData.acf.hero_image_3 != false) {
          let slide3 = this.pageData.acf.hero_image_3;
          this.homeSlider.push({ url: slide3, id: 3 });
        }

        this.sliderLength = this.homeSlider.length;
        console.log("Slider Length: " + this.sliderLength);

        this.timeout = setTimeout(this.startSlider, 10000);
      }
    },

    startSlider() {
      if (this.currentSlide < this.sliderLength) {
        this.currentSlide = this.currentSlide + 1;
      } else if (this.currentSlide === this.sliderLength) {
        this.currentSlide = 1;
      }

      this.timeout = setTimeout(this.startSlider, 10000);
    },
  },

  watch: {},
  metaInfo() {
    return {
      title: this.pageData.yoast_meta.yoast_wpseo_title,
      link: [
        {
          rel: "canonical",
          href: "https://russell-brown.co.uk" + this.$route.path
        }
      ],
      meta: [

            {
              vmid: "description",
              name: "description",
              content: this.pageData.yoast_meta.yoast_wpseo_metadesc
            },

            // Twitter Meta Tags
            {
              vmid: "twitter:card",
              name: "twitter:card",
              content: "summary_large_image"
            },

            {
              vmid: "twitter:title",
              name: "twitter:title",
              content: this.pageData.yoast_meta.yoast_wpseo_title
            },

            {
              vmid: "twitter:image",
              name: "twitter:image",
              content: this.pageData.fimg_url
            },

            {
              vmid: "twitter:description",
              name: "twitter:description",
              content: this.pageData.yoast_meta.yoast_wpseo_metadesc
            },
            //Open Graph Integrations
            {
              vmid: "og:image",
              property: "og:image",
              content: this.pageData.fimg_url
            },
            { vmid: "og:title", property: "og:title", content: this.pageData.yoast_meta.yoast_wpseo_title },
            { vmid: "og:locale", property: "og:locale", content: "en_GB" },
            { vmid: "og:type", property: "og:type", content: "article" },
            { vmid: "og:url", property: "og:url", content: this.pageData.link },
            {
              vmid: "og:site_name",
              property: "og:site_name",
              content: "Russell Brown Interiors"
            },
            {
              vmid: "og:description",
              property: "og:description",
              content: this.pageData.yoast_meta.yoast_wpseo_metadesc
            },
            {
              vmid: "article:modified_time",
              name: "article:modified_time",
              content: this.pageData.modified_gmt + "+00:00"
            },
            {
              vmid: "article:published_time",
              name: "article:published_time",
              content: this.pageData.date_gmt + "+00:00"
            },
            {
              vmid: "og:updated_time",
              name: "og:updated_time",
              content: this.pageData.modified_gmt + "+00:00"
            },

      ]
    };
  }
};
</script>
