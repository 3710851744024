<template>
  <footer>
    <div class="foot-container">
      <div class="col-1">
        <div class="main-menu">
          <router-link to="/">Home</router-link>
          <router-link to="/our-story">Our Story</router-link>
          <router-link to="/our-work">Our Work</router-link>
          <router-link to="/contact">Contact Russell</router-link>
        </div>
        <div class="services">
          <router-link to="/interior-decor-design">Interior Decor & Design</router-link>
          <router-link to="/curtains-furnishings"
            >Curtains & Furnishings</router-link
          >
          <router-link to="/bespoke-decorative-wall-coverings"
            >Bespoke Decorative Wall Coverings
          </router-link>
          <router-link to="/furnishings-fittings"
            >Furnishings and Fittings</router-link
          >
          <router-link to="/hand-painted-kitchens-furniture"
            >Hand Painted Kitchens & Furniture</router-link
          >
        </div>
        <div class="credits">
          <p>
            Copyright Reserved 2021 - Russell Brown Interiors | 
            <router-link to="/privacy-policy">
            Privacy Policy </router-link> 
            |
            <a href="https://design4innovation.co.uk" target="blank"
              >Designed and Built by Design 4 Innovation</a
            >
            |
            <a href="https://www.gecopywriter.com/" target="blank"
              >Content by GECopywriter</a
            >
          </p>
        </div>
      </div>
      <div class="col-2">
        <img
          src="/flex-content/uploads/2020/11/RB-Logo_Dark-Background@2x.png"
          alt="Russell Brown Interiors"
        />
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>