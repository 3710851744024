<template>
  <img :data-src="lazySrc" :data-srcset="lazySrcset" :style="style" :class="classes" />
</template>
<script>
import lozad from "lozad/dist/lozad.min.js";

export default {
  name: "ImgLoader",
  props: {
    backgroundColor: {
      type: String,
      default: ""
    },
    height: {
      type: Number,
      default: null
    },
    lazySrc: {
      type: String,
      default: null
    },
    classes: {
      type: String,
      default: "AppImage"
    },
    lazySrcset: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    aspectRatio() {
      // Calculate the aspect ratio of the image
      // if the width and the height are given.
      if (!this.width || !this.height) return null;

      return (this.height / this.width) * 100;
    },
    style() {
      // The background color is used as a
      // placeholder while loading the image.
      // You can use the dominant color of the
      // image to improve perceived performance.
      // See: https://manu.ninja/dominant-colors-for-lazy-loading-images/
      const style = { backgroundColor: "#" + this.backgroundColor };

      if (this.width) style.width = `${this.width}px`;

      // If the image is still loading and an
      // aspect ratio could be calculated, we
      // apply the calculated aspect ratio by
      // using padding top.
      const applyAspectRatio = this.loading && this.aspectRatio;
      if (applyAspectRatio) {
        // Prevent flash of unstyled image
        // after the image is loaded.
        style.height = 0;
        // Scale the image container according
        // to the aspect ratio.
        style.paddingTop = `${this.aspectRatio}%`;
      }

      return style;
    }
  },
  mounted() {
    // As soon as the <img> element triggers
    // the `load` event, the loading state is
    // set to `false`, which removes the apsect
    // ratio we've applied earlier.
    const setLoadingState = () => {
      this.loading = false;
    };

    this.$el.addEventListener("load", setLoadingState);
    // We remove the event listener as soon as
    // the component is destroyed to prevent
    // potential memory leaks.
    this.$once("hook:destroyed", () => {
      this.$el.removeEventListener("load", setLoadingState);
    });

    const images = document.getElementsByClassName("AppImage");
    for (const image of images) {
      if (!image.getAttribute("data-background-image")) {
        image.onload = function() {
          this.setAttribute("data-fully-loaded", true);
        };
      }
    }

    // We initialize Lozad.js on the root
    // element of our component.
    const observer = lozad(this.$el);
    observer.observe();
  }
};
</script>