<template>
  <transition name="fadeIn">
    <nav v-if="isHidden === false" class="navigation-bar" id="nav">
      <div class="col-1">
        <div @click="menuToggle()" class="menu-button">
          <div :class="menuShow ? 'menu-icon-active' : 'menu-icon'">
            <div class="one" />
            <div class="two" />
            <div class="three" />
          </div>
          <p>{{ menuText }}</p>
        </div>
      </div>
      <div :class="isBig ? 'col-2-big' : 'col-2-nav'">
        <img
          src="/flex-content/uploads/2020/11/RB-Logo_Light-Background@2x-e1606590503194.png"
        />
      </div>
      <div class="gradient" />
      <transition name="fadeInLeft">
        <div class="menu" v-show="menuShow">
          <div class="menu-links">
            <router-link @click.native="menuToggle()" to="/">Home</router-link>
            <router-link @click.native="menuToggle()" to="/our-story"
              >Our Story</router-link
            >
            <router-link @click.native="menuToggle()" to="/our-work"
              >Our Work</router-link
            >
            <p @click="serviceToggle()">Our Services</p>
            <div :class="showService ? 'services-show' : 'services'">
              <router-link @click.native="menuToggle()" to="/interior-decor-design">Interior Decor & Design</router-link>
              <router-link @click.native="menuToggle()" to="/curtains-furnishings">Curtains & Furnishings</router-link>
              <router-link @click.native="menuToggle()" to="/bespoke-decorative-wall-coverings">Bespoke Decorative Wall Coverings </router-link>
              <router-link @click.native="menuToggle()" to="/furnishings-fittings">Furnishings and Fittings</router-link>
              <router-link @click.native="menuToggle()" to="/hand-painted-kitchens-furniture">Hand Painted Kitchens & Furniture</router-link>
            </div>
            <router-link @click.native="menuToggle()" to="/contact"
              >Contact Russell</router-link
            >
          </div>
        </div>
      </transition>
    </nav>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      menuShow: false,
      menuLinksShow: false,
      menuText: "Menu",
      showService: false,
    };
  },
  computed: {
    isBig() {
      return this.$store.state.navLarge;
    },
    isHidden() {
      return this.$store.state.navBarHide;
    },
  },
  methods: {
    serviceToggle() {
      this.showService = !this.showService;
    },
    menuToggle() {
      this.menuShow = !this.menuShow;
      if (this.menuShow === true) {
        this.menuText = "Close";
      } else {
        this.menuText = "Menu";
        this.showService = false;
      }
    },
  },
};
</script>