<template>
    <div class="portfolio-card" :id="this.postId" v-if="(postId != null)">
      <div class="image">
        <imgloader :lazySrc="this.currentImageID" />
      </div>
      <div @click="toggleSlider(true)" class="overlay"></div>
      <div class="overlay-shadow"></div>
      <div class="text-container">
        <p v-html="getCategory(this.portfolioPost.categories[0])"></p>
      </div>
      <img @click="toggleSlider(true)" src="/flex-content/uploads/2020/11/selectarrow-cream.svg" class="arrow"/>
      <transition name="fadeIn">
        <div @click="toggleSlider(false)" v-if="showSlider" class="slideshow">
          <div @click="toggleSlider(false)" class="close">
                <svg @click="toggleSlider(false)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 496.096 496.096" style="enable-background:new 0 0 496.096 496.096;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M259.41,247.998L493.754,13.654c3.123-3.124,3.123-8.188,0-11.312c-3.124-3.123-8.188-3.123-11.312,0L248.098,236.686
                      L13.754,2.342C10.576-0.727,5.512-0.639,2.442,2.539c-2.994,3.1-2.994,8.015,0,11.115l234.344,234.344L2.442,482.342
                      c-3.178,3.07-3.266,8.134-0.196,11.312s8.134,3.266,11.312,0.196c0.067-0.064,0.132-0.13,0.196-0.196L248.098,259.31
                      l234.344,234.344c3.178,3.07,8.242,2.982,11.312-0.196c2.995-3.1,2.995-8.016,0-11.116L259.41,247.998z"/>
                  </g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                </svg>
          </div>
          <div class="selectedImage" @click="toggleSlider(false)">
            <imgloader :key="currentImageID" :lazySrc="this.currentImageID" />
          </div>
          <div @click.stop class="selectableImages">
            <div
              @click="currentImageID = portfolioItem"
              v-for="(portfolioItem, index) in portfolioPost.acf.photos"
              :key="index"
              :class="
                currentImageID === portfolioItem ? 'select-active' : 'select'
              "
            >
              <div>
                <imgloader :lazySrc="portfolioItem" />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
</template>
<script>
import imgloader from "@/partials/ImgLoader.vue";

export default {
  data() {
    return {
      showSlider: false,
      currentImageID: null,
    };
  },
  components: {
    imgloader,
  },
  props: {
    postId: null,
  },
  computed: {
    portfolioPost() {
        let output = this.$store.getters.getPortfolioSingle(this.postId);
        return output;
    },
    startID() {
        return this.portfolioPost.acf.photos[0];
    }
  },
  mounted() {
      this.getStart();
  },

  methods: {
    getStart() {
      this.currentImageID = this.startID;
    },
    getCategory(value) {
      return this.$store.getters.getCategoryName(value);
    },
    toggleSlider(value) {
      this.showSlider = value;
      this.$store.commit('TOGGLE_NAVBAR_HIDE', value);
    },
  },
};
</script>