import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/our-story',
    name: 'Our Story',
    component: () => import(/* webpackChunkName: "ourstory" */ '../views/OurStory.vue')
  },
  {
    path: '/our-work',
    name: 'Our Work',
    component: () => import(/* webpackChunkName: "ourwork" */ '../views/OurWork.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/interior-decor-design',
    name: 'Interior Design',
    component: () => import(/* webpackChunkName: "interiordesign" */ '../views/services/interior-design.vue')
  },
  {
    path: '/bespoke-decorative-wall-coverings',
    name: 'Decorative Treatments',
    component: () => import(/* webpackChunkName: "decorativetreatments" */ '../views/services/decorative-treatments-hangs.vue')
  },
  {
    path: '/curtains-furnishings',
    name: 'Curtains and Soft Furnishings',
    component: () => import(/* webpackChunkName: "curtains" */ '../views/services/curtains-furnishings.vue')
  },
  {
    path: '/furnishings-fittings',
    name: 'Furnishings and Fittings',
    component: () => import(/* webpackChunkName: "furnishing" */ '../views/services/furnishings-fittings.vue')
  },
  {
    path: '/hand-painted-kitchens-furniture',
    name: 'Hand Painted Kitchens & Furniture',
    component: () => import(/* webpackChunkName: "kitchens" */ '../views/services/hand-painted-kitchens.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "kitchens" */ '../views/PrivacyPolicy.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition)
        }, 1000)
      })
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 1000)
      })
    }
  }
})

export default router
